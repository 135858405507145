%svg-common {
	background: url("../sprites/sprite.svg") no-repeat;
}

.svg-arrow-black {
	@extend %svg-common;
	background-position: 98.5747619347922% 60.712606938046434%;
}

.svg-arrow-black-dims {
	width: 14.3px;
	height: 20.3px;
}

.svg-arrow-left {
	@extend %svg-common;
	background-position: 91.31345177664974% 74.15326395458845%;
}

.svg-arrow-left-dims {
	width: 24px;
	height: 27px;
}

.svg-arrow-right {
	@extend %svg-common;
	background-position: 92.83629441624366% 74.15326395458845%;
}

.svg-arrow-right-dims {
	width: 24px;
	height: 27px;
}

.svg-arrows-down {
	@extend %svg-common;
	background-position: 99.43384223918575% 55.42217700915564%;
}

.svg-arrows-down-dims {
	width: 28px;
	height: 101px;
}

.svg-blue-wave {
	@extend %svg-common;
	background-position: 0 67.12666338097584%;
}

.svg-blue-wave-dims {
	width: 1272.6px;
	height: 272.4px;
}

.svg-blue-wave-bottom {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-blue-wave-bottom-dims {
	width: 1600px;
	height: 272.4px;
}

.svg-blue-wave-top {
	@extend %svg-common;
	background-position: 0 33.56333169048792%;
}

.svg-blue-wave-top-dims {
	width: 1600px;
	height: 272.4px;
}

.svg-bullets {
	@extend %svg-common;
	background-position: 99.20754716981132% 60.18639328984156%;
}

.svg-bullets-dims {
	width: 10px;
	height: 11px;
}

.svg-choco-toppings-lefki-sokolata-icon {
	@extend %svg-common;
	background-position: 99.33665008291874% 63.683107979432485%;
}

.svg-choco-toppings-lefki-sokolata-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-choco-toppings-polixrwma-icon {
	@extend %svg-common;
	background-position: 97.53157290470723% 70.34850504665778%;
}

.svg-choco-toppings-polixrwma-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-choco-toppings-sokolata-icon {
	@extend %svg-common;
	background-position: 99.58540630182422% 70.34850504665778%;
}

.svg-choco-toppings-sokolata-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-fb-icon {
	@extend %svg-common;
	background-position: 92.54662379421222% 71.10683349374399%;
}

.svg-fb-icon-dims {
	width: 45px;
	height: 45px;
}

.svg-free-artificial-icon {
	@extend %svg-common;
	background-position: 93.47600518806745% 65.18518518518519%;
}

.svg-free-artificial-icon-dims {
	width: 58px;
	height: 58px;
}

.svg-fruit-fraoula-icon {
	@extend %svg-common;
	background-position: 55.68312284730195% 77.8137497619501%;
}

.svg-fruit-fraoula-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-fruit-frouta_dasous-icon {
	@extend %svg-common;
	background-position: 57.736956244418934% 77.8137497619501%;
}

.svg-fruit-frouta_dasous-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-fruit-moura-icon {
	@extend %svg-common;
	background-position: 59.79078964153592% 77.8137497619501%;
}

.svg-fruit-moura-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-gluten-free-icon {
	@extend %svg-common;
	background-position: 97.23735408560312% 65.18518518518519%;
}

.svg-gluten-free-icon-dims {
	width: 58px;
	height: 58px;
}

.svg-insta-icon {
	@extend %svg-common;
	background-position: 95.44051446945338% 71.10683349374399%;
}

.svg-insta-icon-dims {
	width: 45px;
	height: 45px;
}

.svg-logo {
	@extend %svg-common;
	background-position: 97.18213058419244% 56.74999999999999%;
}

.svg-logo-dims {
	width: 290.5px;
	height: 124px;
}

.svg-logo-sm {
	@extend %svg-common;
	background-position: 88.91839016210172% 65.95660749506904%;
}

.svg-logo-sm-dims {
	width: 168.8px;
	height: 70px;
}

.svg-products-elements-left {
	@extend %svg-common;
	background-position: 88.77572375305196% 73.25731284085275%;
}

.svg-products-elements-left-dims {
	width: 166.5px;
	height: 75.5px;
}

.svg-products-elements-right {
	@extend %svg-common;
	background-position: 42.04862495017935% 91.97523916713563%;
}

.svg-products-elements-right-dims {
	width: 345.5px;
	height: 195.5px;
}

.svg-syrup-caramel-icon {
	@extend %svg-common;
	background-position: 61.8446230386529% 77.8137497619501%;
}

.svg-syrup-caramel-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-syrup-chocolate-icon {
	@extend %svg-common;
	background-position: 63.89845643576988% 77.8137497619501%;
}

.svg-syrup-chocolate-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-syrup-strawberry-icon {
	@extend %svg-common;
	background-position: 65.95228983288686% 77.8137497619501%;
}

.svg-syrup-strawberry-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-syrup-toffee-icon {
	@extend %svg-common;
	background-position: 68.00612323000385% 77.8137497619501%;
}

.svg-syrup-toffee-icon-dims {
	width: 32.2px;
	height: 33.8px;
}

.svg-unique-taste {
	@extend %svg-common;
	background-position: 0 99.96330275229357%;
}

.svg-unique-taste-dims {
	width: 527.5px;
	height: 266.5px;
}

