// INTRO & FOOTER DIVIDERS

:root {
  --section-divider-height: 272.4; // px
  --section-divider-width: 1600; // px
  --section-divider-ratio: calc(100% * var(--section-divider-height) / var(--section-divider-width));
}

.main__header {
	background-color: get-color(primary);
}

.intro {
	@supports (display: grid) {
		display: grid;
		grid-template-rows: auto max-content;
	}
	@supports not (display: grid) {
		position: relative;
		padding-bottom: var(--section-divider-ratio);
	}
	overflow: hidden;
}

.intro__filler {
	@supports (display: grid) {
		background-color: get-color(primary);
		grid-column: 1/2;
		grid-row: 1/2;
	}
}

.intro__wrapper {
	background-color: get-color(primary);
	@supports (display: grid) {
		background-color: transparent;
		grid-column: 1/2;
		grid-row: 1/3;
		padding-bottom: 185px;
	}
}

.section-divider {
	fill: get-color(primary);
	grid-column: 1/2;
	grid-row: 2/3;
	margin-top: -2px;
	@supports not (display: grid) {
		display: block;
		position: absolute;
		left: 0;
		bottom: 1px;
		width: 100%;
		height: auto;
		z-index: -1;
	}
}

.footer__wrapper {
	background-color: get-color(primary);
}

.section-divider-bottom {
	fill: get-color(primary);
}

.icon {
    display: block;
    background-repeat: no-repeat;
}

// NAV
.is-dropdown-submenu {
    z-index: 2;
}

// LOGO

.logo {
	.is-stuck & {
		@extend .svg-logo-sm;
		@extend .svg-logo-sm-dims;
	}
}

// BOXES

.boxed__content {
	padding: rem-calc(45px);
	border: 2px solid $black;
	background-color: $white;
	box-shadow: 1px 1px 0px $black,
                2px 2px 0px $black,
                3px 3px 0px $black,
                4px 4px 0px $black,
                5px 5px 0px $black;
    @include breakpoint(medium down){
		padding: rem-calc(25px);
	}
}

.boxed__content--alt {
	padding: rem-calc(75px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include breakpoint(medium down) {
		padding: rem-calc(25px) rem-calc(25px) rem-calc(45px) rem-calc(25px);
	}
}

// CTA

.cta__button {
	display: block;
	position: absolute;
	background-color: get-color(secondary);
	padding: rem-calc(15px) rem-calc(25px);
	border: 2px solid $black;
	transition: all .1s ease;
	box-shadow: 1px 1px 0px $black,
                2px 2px 0px $black,
                3px 3px 0px $black,
                4px 4px 0px $black,
                5px 5px 0px $black;
    &:hover {
    	box-shadow: 1px 1px 0px $black,
    				2px 2px 0px $black,
            		3px 3px 0px $black;
    	margin-top: 3px;
    	margin-left: 3px;
    }
    &:active {
    	box-shadow: 0px 0px 0px $black;
    	margin-top: 6px;
    	margin-left: 6px;
    }
    @include breakpoint(medium down) {
    	padding: rem-calc(10px);
    }
}

// LINK

.link {
	&:after {
		content: '';
		display: inline-block;
		position: absolute;
		margin-top: 6px;
		@extend .svg-arrow-black;
		@extend .svg-arrow-black-dims;
		margin-left: 15px;
		transition: all .2s ease;
	}
	&:hover {
		color: $black;
		&:after {
			margin-left: 25px;
		}
	}
}

// BODY BACKGROUND

body {
	background-image: url('../img/bg-grid-repeat.png');
	background-repeat: repeat;
}

// HELPERS
.lighter {
	color: $white;
}

.centered {
	text-align: center;
	display: block;
}


// ICONS

.pralinaBase {
	background-size: contain;
	background-position: center;
	width: 33px;
	height: 33px;
}

.pralinaBueno {
	@extend .pralinaBase;
	background-image: url("../img/topping-buono-icon.svg");
}

.pralinaFraoula{
	@extend .pralinaBase;
	background-image: url("../img/topping-fraoula-icon.svg");
} 
.pralinaKaramela{
	@extend .pralinaBase;
	background-image: url("../img/topping-karamela-icon.svg");
}
.pralinaLefkiSokolata{
	@extend .pralinaBase;
	background-image: url("../img/topping-nuss-icon.svg");
}
.pralinaSokolata{
	@extend .pralinaBase;
	background-image: url("../img/topping-pralina-icon.svg");
}

@include breakpoint(medium up) {

	// moving letters services
	/*

	.services-section-moving-letters {
	  position: relative;
	  font-weight: 900;
	  font-size: 3.3em;
	  opacity: 0;
	}

	.services-section-moving-letters .services-moving-text-wrapper {
	  position: relative;
	  display: inline-block;
	  padding-top: 0.2em;
	  padding-right: 0.05em;
	  padding-bottom: 0.1em;
	  overflow: hidden;
	}

	.services-section-moving-letters .letter {
	  display: inline-block;
	  line-height: 1em;
	}

	// moving letters products

	.section-moving-letters {
	  position: relative;
	  font-weight: 900;
	  font-size: 3.3em;
	  opacity: 0;
	}

	.section-moving-letters .moving-text-wrapper {
	  position: relative;
	  display: inline-block;
	  padding-top: 0.2em;
	  padding-right: 0.05em;
	  padding-bottom: 0.1em;
	  overflow: hidden;
	}

	.section-moving-letters .letter {
	  display: inline-block;
	  line-height: 1em;
	}

	// moving letters intro

	.intro-section-moving-letters {
	  position: relative;
	  font-weight: 900;
	  font-size: 3.3em;
	  opacity: 0;
	}

	.intro-section-moving-letters .moving-text-wrapper {
	  position: relative;
	  display: inline-block;
	  padding-top: 0.2em;
	  padding-right: 0.05em;
	  padding-bottom: 0.1em;
	  overflow: hidden;
	}

	.intro-section-moving-letters .letter {
	  display: inline-block;
	  line-height: 1em;
	}


	*/
	// paths

	.the-path-right, .the-path-left {
		z-index: 1;
	}

}


// mobile nav
// .flex-center {
//   display: -webkit-box;
//   display: flex;
//   -webkit-box-align: center;
//           align-items: center;
//   -webkit-box-pack: center;
//           justify-content: center;
// }

// a,
// a:visited,
// a:hover,
// a:active {
//   color: inherit;
//   text-decoration: none;
// }
// h1 {
//   font-family: 'Cera', cursive;
//   font-weight: 400;
//   font-size: 3em;
//   text-align: center;
// }
.outer-menu {
  position: fixed;
  top: 15px;
  left: 0;
  z-index: 2;

  .social__container {
		    margin: 0;
		    list-style: none;
		    display: flex;
		    justify-content: center;
		    .social__link:not(:first-child) {
		        margin-left: 25px;
		    }
		}
}
.outer-menu .checkbox-toggle {
  position: absolute;
  top: 0;
  left: calc(100vw - 77px);
  z-index: 3;
  cursor: pointer;
  width: 60px;
  height: 60px;
  opacity: 0;
}
.outer-menu .checkbox-toggle:checked + .hamburger > div {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.outer-menu .checkbox-toggle:checked + .hamburger > div:before,
.outer-menu .checkbox-toggle:checked + .hamburger > div:after {
  top: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.outer-menu .checkbox-toggle:checked + .hamburger > div:after {
  opacity: 0;
}
.outer-menu .checkbox-toggle:checked ~ .custom__menu {
  pointer-events: auto;
  visibility: visible;
}
.outer-menu .checkbox-toggle:checked ~ .custom__menu > div {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition-duration: 0.75s;
          transition-duration: 0.75s;
          border-radius: 0px;
          flex-direction: column;

}
.outer-menu .checkbox-toggle:checked ~ .custom__menu > div > div {
  opacity: 1;
  -webkit-transition: opacity 0.4s ease 0.4s;
  transition: opacity 0.4s ease 0.4s;
		display: flex;
		flex: 0 1 auto;
		min-height: unset!important;
		max-width: unset!important;
		.main__drilldownMenu {
			display: flex;
			flex: 1;
			justify-content: space-around;
			align-items: center;
			padding: 45px 0;
			ul {
				background: get-color(secondary);
				display: flex;
				flex: 1;
				justify-content: space-around;
				align-items: center;
				padding: 45px 0;
			}
			a {
				background: unset;
				color: $white;
				&:focus {
					outline: none;
				}
				&:after {
					border-color: transparent transparent transparent #ffffff;
					position: absolute;
					right: 0;
				}
				&:before {
					border-color: transparent transparent transparent #ffffff;
				}
			}
			.js-drilldown-back {
				a {
					&:before {
						border-color: transparent #ffff transparent transparent;
					}
				}
			}
		}
}
.outer-menu .checkbox-toggle:hover + .hamburger {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}
.outer-menu .checkbox-toggle:checked:hover + .hamburger > div {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}
.outer-menu .hamburger {
  position: absolute;
  top: 0;
  left: calc(100vw - 77px);
  z-index: 1;
  width: 60px;
  height: 60px;
  padding: 0.5em 1em;
  background: rgba(216, 168, 38, 0.75);
  border-radius: 0 0.12em 0.12em 0;
  cursor: pointer;
  -webkit-transition: box-shadow 0.4s ease;
  transition: box-shadow 0.4s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.outer-menu .hamburger > div {
  position: relative;
  -webkit-box-flex: 0;
          flex: none;
  width: 100%;
  height: 2px;
  background: #FEFEFE;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.outer-menu .hamburger > div:before,
.outer-menu .hamburger > div:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background: inherit;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.outer-menu .hamburger > div:after {
  top: 10px;
}
.outer-menu .custom__menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  outline: 1px solid transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.outer-menu .custom__menu > div {
  width: 200vw;
  height: 200vw;
  color: #FEFEFE;
  background: rgba(216, 168, 38, 0.97);
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-flex: 0;
          flex: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.outer-menu .custom__menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  overflow-y: auto;
  -webkit-box-flex: 0;
          flex: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.outer-menu .custom__menu > div > div > ul {
  list-style: none;
  padding: 0 1em;
  margin: 0;
  display: block;
  max-height: 100vh;
  .is-drilldown-submenu-parent {
  	a {
  		
  	}
  }
}
.outer-menu .custom__menu > div > div > ul > li {
  padding: 0;
  margin: 1em;
  font-size: 24px;
  display: block;

}
.outer-menu .custom__menu > div > div > ul > li > a {
  position: relative;
  display: inline;
  cursor: pointer;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease;
}

// frontpage element svg

.unique__taste {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -70px;
	margin-left: -253px;
	@include breakpoint(medium up) {
		opacity: 0;
	}
	@include breakpoint(medium down){
		margin-top: -35px;
		margin-left: -200px;
	}
	svg {
		width:40rem;
		@include breakpoint(medium down){
			width: 25rem;
		}
	}
}

//drilldown sub
.drilldown .is-drilldown-submenu {
	transition: transform .3s ease;
}