// HEADER

.main__header {
	transition: all .2s ease;
	@include breakpoint(medium down) {
		display: flex;
		justify-content: flex-end;
		padding: rem-calc(20px);
	}
}

.js-header__container {
	background-color: $primary-color;
	z-index: 5;
	padding-bottom: 25px;
	@include breakpoint(medium down) {
		padding-bottom: 0px;
	}
	&.is-stuck {
		padding-bottom: 0px;
	}
}

// MOBILE HEADER 

.mobile__header {
	padding: rem-calc(15px);
	display: flex;
	justify-content: space-between;
	background-color: $primary-color;
	.navigation__trigger {
		> a {
			padding: 10px;
			border: 1px solid $white;
			transition: background .2s;
			display: block;
			color: $white;
			text-align: center;
			width: 150px;
			&:hover{
				background-color: $white;
				color: $primary-color;
			}
		}
	}
}

// NAVIGATION

.main__nav {
	@include xy-grid-container(110rem);
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 25px;
	@include breakpoint(medium down) {
		flex-direction: column;
		align-items: center;
		background-color: get-color(secondary);
		overflow: hidden;
	}
	.nav__container {

		&:last-child {
			justify-content: flex-end;
		}

		list-style: none;
		margin: 0;
		display: flex;
		flex: 1;
		@include breakpoint(medium down) {
			flex-direction: column;
			align-items: center;
		}
		@include breakpoint(medium down) {
			&.logo__container {
				display: none;
			}
		}

		&.logo__container {
			justify-content: center;
			flex: 0 1 auto;
		}
		
		.nav__item {
			&:not(:last-child) {
				margin-right: 25px;
			}
			.is-dropdown-submenu {
				list-style: none;
				li {
					a {
						color: get-color(secondary);
						}
					&.current-menu-item {
						a {
							color: $black;

						}
					}
				}
			}
			
			// flex: 1;
			text-align: center;
			&:not(.logo__item) {
				height: fit-content;
				padding: 10px 0;
				> a {
					color: $white;
					// padding: 0px;
					&:hover {
						color: $black;
						@include breakpoint(medium down) {
							color: get-color(primary);
						}
					}
					&:focus {
						color: $white;
						outline: none;
					}
					&:after {
						border-color: $white transparent transparent;
					}

					&:hover {
						&:after, &:focus {
							border-color: $black transparent transparent;
						}
					}
				}
				@include breakpoint(medium down) {
					margin-top: 0;
					display: flex;
					align-items: center;
					&.menu-item-51 {
					> a {
						padding: 10px;
						color: #fff !important;
						}
					}
				}
				&.menu-item-51 {
					> a {
					border: 1px solid $white;
					transition: all .2s;
					display:block;
					color: #fff !important;
					@include breakpoint(medium down) {
						padding: 10px;
					}						
					&:hover{
						background-color: $white;
						color: $black!important;
						}
					}
				}
			}

		}
		.current-menu-item, .current-menu-parent  {
			&.nav__item {

				&:not(.logo__item) {
					> a {
						&:after {
							border-color: $black transparent transparent;
						}
					}
				}

				a {
					color: $black;
				}
				// &.menu-item-51 {
				// 	a {
				// 		background-color: $white;
				// 		color: $primary-color;
				// 	}
				// }
			}
		}
		.logo__image {
			width: 250px;
			transition: all .2s ease;
			.is-stuck & {
				width: 150px;
			}
		}
	}

	.is-stuck & {
		height: 90px;
		padding-top: 0px;
		transition: all .2s ease;
	}
	height: auto;
	transition: all .2s ease;
}

// LOGO

.logo {
	margin: 0 auto;
}

// INTRO

.intro {
	.intro__wrapper {
		.intro__container {
			@include xy-grid-container(90rem);
			display: grid;
			grid-template-columns: 50% auto;
			@include breakpoint(medium down) {
				grid-template-columns: 1fr;
			}
			// experimentation on small screen
			@include breakpoint(small only) {
				position: relative;
    			overflow: hidden;
			}
			.services &, .single-recipe &, .privacy-policy &, .cookies-policy &{
				display: block;
			}
			.intro__details {
				@include xy-grid-container();
				grid-column: 1/2;
				grid-row: 1/2;
				padding-top: 50px;
				padding-bottom: 50px;
				display: grid;
				grid-template-rows: repeat(4, max-content);
				z-index: 1;
				.intro__deco__text--alt {
					display: block;
				}
			}
			.intro__animation {
				z-index: 0;
				@include breakpoint(medium down) {
					display: none;
				}
				grid-column: 2/3;
				grid-row: 1/2;
				.svg--animation {
					width: 100%;
				}
				img {
					float: right;
					width: 57%;
				}
				.page & {
					display: flex;
					position: relative;
					align-items: center;
    				justify-content: center;
					img {
						width: unset;
						top: calc(40% - 90px);
					    position: absolute;
					    left: calc( 50% - 60px);
					}
				}
			}
			.intro__animation {
				.home & {
					@include breakpoint(medium down) {
						display: none;
					}
					// experimentation on small screen
					@include breakpoint(medium down) {
						left: -100px;
						width: 1200px;
						display: flex;
					}
					grid-column: 1/3;
					grid-row: 1/2;
					display: unset;
					.svg--animation {
						width: 51%;
						float: right;
						right: 0%;
						position: absolute;
						top: 23px;
						z-index: -1;
						@include breakpoint(medium down) {
							display: none;
						}
					}
					img {
						float: right;
						width: 45%;
						top: 110px;
						position: absolute;
						left: calc( 50% - -22px);
						// experimentation on small screen
						@include breakpoint(medium down) {
							float: right;
							width: 57%;
							top: unset;
							position: unset;
							opacity: .45;
						}
					}
				}
			}
		}
	}
}

// SECTIONS

.services__section, .products__section, .recipes__section, .bakers__section {
	padding: 55px 0;
	overflow: hidden;
	@include breakpoint(medium down) {
		padding: 15px 0;
	}
}

// SERVICES

.services__section {
	@include breakpoint(medium down) {
		overflow: hidden;
	}
	.section__container {
		@include breakpoint(medium down) {
			overflow: hidden;
		}
		position: relative;
		@include xy-grid-container();

		.section__content {
			margin-top: 70px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: min-content 1fr;
			grid-column-gap: rem-calc(35px);
			grid-row-gap: rem-calc(35px);
			@include breakpoint(medium down) {
				grid-template-columns: 1fr;
				grid-template-rows: auto;
			}
			.boxed__content {
				grid-column: 1/2;
				grid-row: 1/2;
				@include breakpoint(medium down) {
					grid-column: 1/2;
					grid-row: 1/2;
				}
			}
			.section__image--waffle {
				grid-column: 2/3;
				grid-row: 1/3;
				// @include breakpoint(medium up) {
				// 	height: 0%;
				// }
				@include breakpoint(medium down) {
					grid-column: 1/2;
					grid-row: 2/3;
				}
				img {
					height:100%;
					width: 100%;
					object-fit: cover;
				}
			}
			.section__image--pancake {
				grid-column: 1/2;
				grid-row: 2/3;
				// @include breakpoint(medium up) {
				// 	width: 0%;
				// }
				@include breakpoint(medium down) {
					grid-column: 1/2;
					grid-row: 3/4;
				}
				img {
					height:100%;
					width: 100%;
					object-fit: cover;
				}
			}
		}
		// .unique__taste {
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 50%;
		// 	margin-top: -70px;
		// 	margin-left: -253px;
		// 	@include breakpoint(medium up) {
		// 		opacity: 0;
		// 	}
		// }
		.cta__container {
			display: flex;
			justify-content: flex-end;
			margin-top: -130px;
			margin-right:-25px;
			margin-bottom: 145px;
			.cta__button {
				width: 350px;
			}
		}
		.deco__text {
			display: block;
			text-align: end;
		}
	}
}

// PRODUCTS

.products__section {
	.section__container {
		position: relative;
		@include xy-grid-container();
		// .section__subtitle {
		// 	@include breakpoint(medium up) {
		// 		opacity: 0;
		// 	}
		// }
		.section__content {
			margin-top:70px;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: rem-calc(35px);
			@include breakpoint(medium down) {
				grid-template-columns: 1fr;
			}
			.product__container {
				display: grid;
				grid-template-rows: auto 30px auto;
				// @include breakpoint(medium up) {
				// 	opacity: 0;
				// }
				@include breakpoint(medium down) {
					margin-bottom: 75px;
				}
				.boxed__content {
					grid-row: 1/3;
					grid-column: 1/2;
				}
				.cta__container {
						grid-row: 2/4;
						grid-column: 1/2;
						margin-left: calc(50% - 122px);
					.cta__button {
						width: 244px;
					}
				}
			}
		}
	}
}

// RECIPES

.recipes__section, .reference__section {
	overflow: hidden;
	.section__container {
		@include xy-grid-container();
		.link__container {
			position: relative;
			margin-top: 90px;
			display: block;
			text-align: end;
			@include breakpoint(medium down) {
				margin-top: 0;
			}
		}
		.section__content {
			margin-top: 70px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: rem-calc(35px);
			@include breakpoint(medium down) {
				grid-template-columns: 1fr;
			}
			.reference__section &{
				grid-template-columns: 1fr;
			}
			.recipe__container {
				display: grid;
				grid-template-rows: auto 30px auto;
				@include breakpoint(medium down) {
					margin-bottom: 75px;
				}
				.boxed__content--alt {
					grid-row: 1/3;
					grid-column: 1/2;
				}
				.cta__container {
					grid-row: 2/4;
					grid-column: 1/2;
					margin-left: calc(50% - 137px);
					.cta__button {
						width: 274px;
					}
				}
			}
		}
	}
}

// PRODUCT PAGE

.product__section {
	overflow: hidden;
	.section__container {
		@include xy-grid-container();
		.section__header--wrapper {
			padding: rem-calc(25px) rem-calc(25px) rem-calc(110px) 0;
			position: relative;
			&:after {
				content: '';
			    position: absolute;
			    top: calc(50% - 50px);
			    right: 0;
			    @extend .svg-arrows-down-dims;
			    @extend .svg-arrows-down;
			}
		}
		.product__container {
			&:not(:last-child) {
				margin-bottom: rem-calc(120px);
			}
		}
		.product__title--wrapper {
			margin: 0 auto 3rem auto;
			width: fit-content;
			border-style: solid;
			border-width: 12px 12px 12px 12px;
			border-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2NTEgNzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDY1MSA3NjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNEOUE4MjY7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNjQyLjQsNWMtOS4yLTIuOS0xOS44LTIuOC0yOS4zLTIuN2MtMjAuOSwwLjItNDEuNywyLjktNjIuNSwzLjdjLTIyLDAuOS0xMjEuOSwxLTE0My45LDAuNwoJYy00My4yLTAuNi04Ni4zLTIuOC0xMjkuNS0zLjNjLTIxLjEtMC4zLTQyLjMtMC4xLTYzLjQsMC43Yy0yMS42LDAuOS00MywzLjQtNjQuNiw0QzEwNi45LDkuMyw2NC40LDkuNywyMi4zLDUuOQoJQzE2LDUuMywxMC41LDUuMyw2LjUsMTAuNGMtMC4zLDAuMi0wLjUsMC40LTAuNywwLjdjLTMuOCw2LjUtMy40LDE2LjctMy41LDI0Yy0wLjIsOS40LDAuNCwxOS4yLDIuMiwyOC40YzAuNiwyLjksMS42LDUuNyw0LjYsNi44CgljMy44LDEuNCw5LDAuOCwxMywxYzkuOCwwLjUsMTkuNiwwLjksMjkuNSwxLjJjMzkuNywxLjQsNzkuNywyLjEsMTE5LjQtMWMyMC44LTEuNiw0MS40LTQuNyw2Mi4xLTYuOWMyMS45LTIuMyw0My41LTIuNiw2NS40LTAuOQoJYzIxLjYsMS43LDQzLjEsNCw2NC43LDQuOGMyMS43LDAuOCw0My40LDAuOSw2NS4xLDEuMWMyMS45LDAuMiwxMjEuOCwwLjUsMTQzLjcsMC43YzExLDAuMSwyMS45LDAuMiwzMi45LDAuMwoJYzUuMywwLjEsMTAuNiwwLjEsMTUuOSwwLjJjNC4xLDAsOC4zLDAuNCwxMi4zLTAuNmM4LTEuOSw4LjktOC40LDkuOS0xNS41YzEuNi0xMC42LDMuMS0yMS4yLDQuNy0zMS45QzY0OC43LDE2LjEsNjUxLDcuNiw2NDIuNCw1egoJIi8+Cjwvc3ZnPgo=) 9 12 12 5 stretch stretch;
			@include breakpoint(medium down) {
				border: unset;
				border-radius: 15px;
				border-image: none;
				border-width: 0;
			}
			.product__title {
				background-color: get-color(secondary);
				display: block;
				padding: 0 25px;
				@include breakpoint(medium down) {
					padding: 5px 15px;
					border-radius: 15px;
					border: unset;
					border-radius: 15px;
					border-image: none;
					border-width: 0;
				}
			}
		}
		.product__information {
			display: flex;
			flex: 1;
			position: relative;
			flex-direction: row;
			align-items: center;
			    margin-bottom: 50px;
			@include breakpoint(medium down) {
				flex-direction: column;
			}
			.boxed__content {
				width: 400px;
				padding: 15px;
				@include breakpoint(medium down) {
					width: 100%;
					margin-bottom: 2rem;
				}
				.orbit-next, .orbit-previous {
					&:hover, &:active, &:focus {
						background: none;
					}
					i {
						display: block;
						cursor: pointer;
					}
				}
			}
			.decoration__image {
				position: absolute;
    			right: -15%;
    			@include breakpoint(medium down) {
    				display: none;
    			}

    			img {
    				display: block;
    				max-height: 250px;
    			}
			}
			.characteristics__content {
				padding: rem-calc(15px) rem-calc(65px);
				display: flex;
				flex-direction: column;
				@include breakpoint(medium down) {
					padding: rem-calc(15px) rem-calc(25px);
				}
				.characteristics__header {
					margin-bottom: 2rem;
				}
				.characteristics__list {
					list-style: none;
					margin: 0;
					.characteristic {
						margin-left: 25px;
						position: relative;
					}
					.characteristic:before {
						content: '';
						display: inline-block;
						margin-right: 15px;
						@extend .svg-bullets;
						@extend .svg-bullets-dims;
						position: absolute;
						top: 11px;
						left: -25px;
					}
				}
				.info {
					justify-self: flex-end;
					display: flex;
					flex: 1;
					align-items: flex-end;
					margin-top: 2rem;
					.info__wrapper {
						border-style: solid;
						border-width: 12px 12px 12px 12px;
						border-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyODggMTE3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyODggMTE3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzU0QkZCRjt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTQsOS40QzAuOCwzMi4zLDAuNiw1NC44LDAuNSw3Ny45QzAuNCw4OS43LTMsMTAzLjQsOS40LDExMS4xYzE0LjEsOC43LDQzLDMuNCw1OC43LDMuMgoJCWMyOC41LTAuMyw1Ny0wLjQsODUuNSwwYzI4LjUsMC40LDU3LDEuNCw4NS41LDIuM2MxMy40LDAuNCwyNy4yLDIuMiwzNy44LTUuOGM4LjgtNi42LDkuMi0xNC42LDEwLjEtMjMuOQoJCWMyLjUtMjUtMC42LTUxLjctMS4yLTc2LjdjLTAuMS00LjgtNS43LTkuNy0xMS41LTkuNkMxOTAsMS41LDEwNS42LTAuNiwyMS4yLDAuMkM0LjQsMC4zLDUuMiwxLjIsNCw5LjQiLz4KPC9nPgo8L3N2Zz4K) 6 12 12 12 stretch stretch;
						@include breakpoint(medium down) {
							border: unset;
							border-radius: 15px;
							border-image: none;
							border-width: 0;
						}
						.info__list {
							margin: 0;
							list-style: none;
							padding: 15px;
							background-color: get-color(primary);
							@include breakpoint(medium down) {
								border-radius: 15px;
							}
						}
					}
				}
			}
		}
	}
}

// MODIFICATIONS FOR MIXES

.mixes__section {
	.section__container {
		.product__information {
			.boxed__content {
				flex: 1 0 auto;
			}
			.characteristics__content {
				align-self: flex-start;
				flex: 1 0 40%;
				padding: 25px;
			}
			.info {
				position: absolute;
				bottom: -30px;
				left: 350px;
				@include breakpoint(medium down) {
			    	position: inherit;
			    	bottom: unset;
			    	left: unset;
			    }
			}
			.decoration__image {
				position: unset;
				right: unset;
				flex: 1 1 auto;
				@include breakpoint(medium down) {
			    	display: block;
			    }
			}
		}
	}
}

// MODIFICATIONS FOR TOPPINGS

.toppings__section {
	.section__container {
		.product__information {
			align-items: flex-start;
			.boxed__content {
				flex: 1 0 auto;
			}
			.characteristics__content {
				align-self: flex-start;
				flex: 1 0 40%;
				padding: 35px 55px;
				.characteristics__list {
					margin-left: 50px;
					position: relative;
					.topping-characteristic:before {
						content: '';
						display: block;
						position: absolute;
						left: -65px;
						width: 60px;
						height: 60px;
						&.caramel {
							@extend .svg-syrup-caramel-icon;
							@extend .svg-syrup-caramel-icon-dims;
						}
						&.chocolate {
							@extend .svg-syrup-chocolate-icon;
							@extend .svg-syrup-chocolate-icon-dims;
						}
						&.strawberry {
							@extend .svg-syrup-strawberry-icon;
							@extend .svg-syrup-strawberry-icon-dims;
						}
						&.toffee {
							@extend .svg-syrup-toffee-icon;
							@extend .svg-syrup-toffee-icon-dims;
						}
						&.choco-toppings-sokolata {
							@extend .svg-choco-toppings-sokolata-icon;
							@extend .svg-choco-toppings-sokolata-icon-dims;
						}
						&.choco-toppings-lefki-sokolata {
							@extend .svg-choco-toppings-lefki-sokolata-icon;
							@extend .svg-choco-toppings-lefki-sokolata-icon-dims;
						}
						&.choco-toppings-polixrwma {
							@extend .svg-choco-toppings-polixrwma-icon;
							@extend .svg-choco-toppings-polixrwma-icon-dims;
						}
						&.pralina-bueno {
							@extend .pralinaBueno !optional;
						}
						&.pralina-fraoula {
							@extend .pralinaFraoula !optional;
						}
						&.pralina-karamela {
							@extend .pralinaKaramela !optional;
						}
						&.pralina-lefki-sokolata {
							@extend .pralinaLefkiSokolata !optional;
						}
						&.pralina-sokolata {
							@extend .pralinaSokolata !optional;
						}
						&.fruit-toppings-frouta_dasous {
							@extend .svg-fruit-frouta_dasous-icon !optional;
							@extend .svg-fruit-frouta_dasous-icon-dims !optional;
						}
						&.fruit-toppings-fraoula {
							@extend .svg-fruit-fraoula-icon !optional;
							@extend .svg-fruit-fraoula-icon-dims !optional;
						}
						&.fruit-toppings-moura {
							@extend .svg-fruit-moura-icon !optional;
							@extend .svg-fruit-moura-icon-dims !optional;
						}
					}
					.characteristic__info {
						margin: 0;
						list-style: none;
					}
				}
			}
			.more__info {
				position: unset;
				right: unset;
				flex: 1 1 auto;
				@include breakpoint(medium down) {
			    	display: block;
			    }
			}
			.decoration__image {
				position: unset;
				right: unset;
				flex: 1 1 auto;
				display: flex;
    			flex-direction: column;
				@include breakpoint(medium down) {
			    	flex-direction: row;
			    	i:not(:last-child) {
			    		margin-right: 15px;
			    	}
			    }
			    .icon {
			    	&:not(:last-child) {
				    	margin-bottom: 15px;
				    }
			    }
			    
			}
		}
	}
}

// MODIFICATIONS FOR REFERENCE

.reference__section {
	overflow: hidden;
	.section__container {
		@include xy-grid-container();
		.section__content {
			display: grid;
			grid-template-columns: 1fr;
			.recipe__container {
				display: grid;
				grid-template-rows: auto 30px auto;
				grid-template-columns: 1fr 1fr;
				@include breakpoint(medium down) {
					margin-bottom: 75px;
				}
				.boxed__content--alt {
					grid-row: 1/3;
					grid-column: 1/3;
				}
				.cta__container_one {
					grid-row: 2/4;
					grid-column: 1/2;
					// margin-left: calc(50% - 137px);
					display: flex;
					justify-content: center;
					@include breakpoint(medium down) {
						margin-left: calc(50% - 100px);
					}
					.cta__button {
						width: 274px;
						@include breakpoint(medium down) {
							width: 40%;
							margin-left: 5%;
						}
						@include breakpoint(small only) {
							margin-left: 0%;
						}
					}
				}
				.cta__container_two {
					grid-row: 2/4;
					grid-column: 2/3;
					// margin-left: calc(50% - 137px);
					display: flex;
					justify-content: center;
					@include breakpoint(medium down) {
						margin-left: calc(50% - 100px);
					}
					.cta__button {
						width: 274px;
						@include breakpoint(medium down) {
							width: 40%;
							margin-left: 5%;
						}
						@include breakpoint(small only) {
							margin-left: 0%;
						}
					}
				}
			}
		}
	}
}

// SERVICE PAGE

.steps__section {
	padding: 120px 0;
	@include breakpoint(medium down) {
		padding: 190px 0 65px 0;
	}
	.section__container {
		@include xy-grid-container();
		.steps__content {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 25px;
			@include breakpoint(medium down) {
				grid-template-columns: 1fr;
			}
			.step__wrapper {
				@include breakpoint(medium down) {
					&:not(:last-child){
						margin-bottom: 170px;
					}
				}
				&:nth-child(2) {
					margin-top: 60px;
					@include breakpoint(medium down) {
						margin-top: 0;
					}
				}
				&:nth-child(3) {
					margin-top: -30px;
					@include breakpoint(medium down) {
						margin-top: 0;
					}
				}
				.step {
					position: relative;
					.step__no, .step__img {
						position: absolute;
					}
					.step__no {
						top: -150px;
						left: 25px;
					}
					.step__img {
						top: -145px;
						right: 0;
						img {
							width: 235px;
						}
					}
					.step__info {
						padding-top:30%;
					}
				}
			}
		}
	}
}

.qa__upper {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 250px 100px auto;
	.qa__backdrop{
		grid-row: 1/3;
		grid-column: 1/2;
		background-size: cover;
    	background-repeat: no-repeat;
    	background-position: center;

	}
	.qa__head {
		@include xy-grid-container(1200px);
		grid-row: 2/4;
		grid-column: 1/2;
	}
}

.qa__lower {
	@include xy-grid-container(960px);
	padding: 75px 0;
	.accordion {
		background: transparent;
		.accordion-title {
			padding: 1.8rem 1rem;
			border-left: none;
			border-right: none;
		}
		.accordion-content {
			background: transparent;
			border: none;
		}
	}
}

// RECIPES PAGE

.recipe__explanation {
	@include breakpoint(medium down) {
		margin-top: 55px;
	}
	.section__container {
		@include xy-grid-container();
		margin-bottom: 2rem;
		.boxed__content {
			padding: rem-calc(45px) rem-calc(110px);
			@include breakpoint(medium down) {
				padding: rem-calc(20px);
			}
		}
		.recipe__base--title {
			margin-bottom: 2.5rem;
		}
		.recipe__info {
			display: flex;
			flex-direction: row;
			flex: 0 1 auto;
			@include breakpoint(medium down) {
				flex-direction: column;
			}
			> ul {
				margin: 0;
				list-style: none;
			}
			.recipe__ingredients {
				padding-right: rem-calc(45px);
				@include breakpoint(medium down) {
					padding-right: 0;
					margin-bottom: 3rem;
				}
				.recipe__ingredient {
					&:not(:last-child) {
						margin-bottom: 2.5rem;
						@include breakpoint(medium down) {
							margin-bottom: 1.2rem;
						}
					}
				}
			}
			.recipe__instruction {
				margin-left: 20px;
				position: relative;
				&:before {
					margin-left: -20px;
					top: 8px;
					content: '';
					display: block;
					position: absolute;
					@extend .svg-bullets;
					@extend .svg-bullets-dims;
				}
				&:not(:last-child) {
						margin-bottom: 1.2rem;
					}
			}
		}
	}
}

.recipes__container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 75px;
	@include breakpoint(medium down) {
		grid-template-columns: 1fr;
	}
	figcaption {
		background: get-color(secondary);
		padding: 15px;
		color: white;
	}
}

.recipe__intro {
	margin-top: rem-calc(55px);
	.section__container {
		@include xy-grid-container();
		display: grid;
		grid-template-columns: 100px 33% auto;
		grid-template-rows: auto 150px auto;
		@include breakpoint(medium down) {
			grid-template-columns: 1fr;
			grid-template-rows: auto;
			grid-row-gap: rem-calc(25px);
		}
		.recipe__image--wrapper {
			grid-column: 2/4;
			grid-row: 1/3;
			width: 100%;
			object-fit: cover;
			@include breakpoint(medium down){
				grid-column: 1/2;
				grid-row: 1/2;
			}
			img {
				width: 100%;
			}
		}
		.boxed__content {
			grid-column: 1/3;
			grid-row: 2/4;
			align-self: start;
			@include breakpoint(medium down){
				grid-column: 1/2;
				grid-row: 2/3;
			}
		}
		.recipe__instructions--container{
			grid-column: 3/4;
			grid-row: 3/4;
			padding: rem-calc(45px) rem-calc(25px) rem-calc(25px) rem-calc(25px);
			@include breakpoint(medium down){
				grid-column: 1/2;
				grid-row: 3/4;
				padding: rem-calc(15px);
			}
			.recipe__instructions {
				list-style: none;
				margin: 0;
				.recipe__instruction {
					margin-left: 20px;
					position: relative;
					&:before {
						margin-left: -20px;
						top: 8px;
						content: '';
						display: block;
						position: absolute;
						@extend .svg-bullets;
						@extend .svg-bullets-dims;
					}
					&:not(:last-child) {
						margin-bottom: .8rem;
					}
				}
			}
		}
	}
}

.recipe__instructions--section {
	margin: rem-calc(55px) 0;
	.section__container {
		@include xy-grid-container();
		.recipe__topping--text, .recipe__baking--method ul {
			list-style: none;
			margin: 0;
			.recipe__topping--step, li {
				margin-left: 20px;
				position: relative;
				&:before {
					margin-left: -20px;
					top: 8px;
					content: '';
					display: block;
					position: absolute;
					@extend .svg-bullets;
					@extend .svg-bullets-dims;
				}
				&:not(:last-child) {
					margin-bottom: 1.2rem;
				}
			}
		}
	}
}



// FOOTER

.footer {
	display: grid;
	grid-template-rows: auto auto auto auto;
	grid-template-columns: 1fr;
	.quote__section {
		grid-column: 1/2;
		grid-row: 1/3;
		padding-top: 90px;
    	padding-bottom: 6px;
		.section__container {
		@include xy-grid-container();
			.boxed__content {
				max-width: 920px;
		    	margin: 0 auto;
				.cta__container {
					display: flex;
					justify-content: center;
					margin-bottom: 55px;
					.cta__button {
						display: block;
						position: absolute;
						width: 300px;
					}
				}
			}
		}
	}
	.section-divider-bottom {
		grid-column: 1/2;
		grid-row: 2/3;
		margin-bottom: -1px;
	}
	.footer__wrapper {
		grid-column: 1/2;
		grid-row: 3/4;
		padding-top: 50px;
		.footer__container {
			@include xy-grid-container();
			.social__container {
				margin: 0;
				list-style: none;
				display: flex;
				justify-content: center;
				.social__link {
					&:not(:first-child) {
						margin-left: 25px;
					}
				}
			}
			.policies__container {
				margin: 0 0 rem-calc(10px) 0;
				list-style: none;
				display: flex;
				justify-content: center;
				color: $white;
				padding-top: 55px;
				.policies__link {
					a{
						color: $white;
					}
					&:not(:first-child) {
						&:before {
							content: '\00B7';
							padding: 0 10px;
						}
					}
				}
			}
			.copyright {
				color: $white;
				text-align: center;
				padding-bottom: 55px;
				a {
					color: $white;
				}
			}
		}
	}
	.services & {
		.quote__section {
			grid-row: 2/3;
			background-color: get-color(primary);
		}
		.section-divider-bottom {
			grid-column: 1/2;
			grid-row: 1/2;
			margin-bottom: -1px;
		}
	}
}

// 404

.section__404 {
	padding: rem-calc(70px) rem-calc(10px);
	@include breakpoint(medium down) {
		font-size: rem-calc(40px) rem-calc(10px);
	}
	.section__container {
		display: flex;
		align-items: center;
		justify-content: center;
		.contains__image {
			flex: 0 0 auto;
		}
		.the__image {
			animation: rotating 5s linear infinite;
		}
	}
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// fix canvas

.inTheCanvas {
	overflow: hidden;
}