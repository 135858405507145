@font-face {
    font-family: 'Cera';
    src: url('../fonts/CeraGr.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cera';
    src: url('../fonts/CeraGrBold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Cera';
    src: url('../fonts/CeraGrBlack.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Rukola';
    src: url('../fonts/Rukola.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

.nav__anchor {
	font-family: $body-font-family;
	font-weight: 700;
	font-size: rem-calc(16pt);
	@include breakpoint(medium down) {
		font-size: rem-calc(20px)
	}
	color: $white;
	.is-active & {
		.dropdown.menu &{
			color: $white;
		}
	}
}

.intro__header {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(36pt);
	color: $white;
	.single &, .page & {
		@supports (-webkit-text-stroke: 1px black) {
		    -webkit-text-stroke: 1px black;
		    -webkit-text-fill-color: white;
		}
		text-align: center;
		font-size: rem-calc(50pt);
		@include breakpoint(medium down) {
			font-size: rem-calc(32pt);
		}
	}
}

.intro__lead {
	.single &, .page & {
		color: $white;
		font-family: $body-font-family;
		font-weight: 400;
		font-size: rem-calc(18pt);
		text-align: center;
	}
	.single-recipe & {
		color: $white;
	}
}

.intro__deco--text {
	font-family: $rukolaFont;
	font-weight: 400;
	font-size: rem-calc(50pt);
	color: get-color(secondary);
	text-shadow: 2px 2px $black;
	.animated-word {
		display: inline-block;
	}
	.single &, .page & {
		text-align: center;
		font-size: rem-calc(36pt);
	}
}


.intro__deco__text--alt {
	font-family: $body-font-family;
	font-weight: 400;
	// font-size: rem-calc(28pt);
	color: $black;
	margin-top: 1rem;
    margin-bottom: 2rem;
	font-size: 1.2rem;
	line-height: 1.8rem;
	@include breakpoint(medium down){
		font-size: rem-calc(22pt);
		color: $white;
	}
}

.cta__button {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(18pt);
	color: $white;
	text-align: center;
	@supports (-webkit-text-stroke: 1px black) {
	    -webkit-text-stroke: 1px black;
	    -webkit-text-fill-color: white;
	}
	.quote__section & {
		@supports (-webkit-text-stroke: 1px black) {
		    -webkit-text-stroke: unset;
		    -webkit-text-fill-color: white;
		}
	}
}

.section__header {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(70pt);
	color: get-color(primary);
	text-align: center;
	@include breakpoint(medium down) {
		font-size: rem-calc(28pt);
	}
	.single &, .page & {
		text-align: left;
		font-size: rem-calc(32pt);
	}
	.single-recipe & {
		text-align: center;
	}
}

.section__subtitle {
	font-family: $body-font-family;
	font-weight: 700;
	font-size: rem-calc(18pt);
	color: $black;
	text-align: center;
	.single &, .page & {
		text-align: left;
		font-weight: 400;
		max-width: 60rem;
	}
	.single-recipe &, .home & {
		text-align: center;
		max-width: 60rem;
		margin: auto;
	}
}

.boxed__content--header {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(32pt);
	color: get-color(secondary);
	line-height: 2.2rem;
	margin-bottom: 15px;

	@include breakpoint(medium down){
		font-size: rem-calc(22pt);
		line-height: 1.6rem;
	}
	.quote__section & {
		text-align: center;
	}
	.boxed__content--header-alt {
		@include breakpoint(medium down) {
			font-size: rem-calc(20pt);
		}
	}
}

.boxed__content p {
	font-family: $body-font-family;
	font-weight: 400;
	font-size: rem-calc(18pt);
	color: $black;
	@include breakpoint(medium down) {
		font-size: rem-calc(16pt);
	}
	.quote__section & {
		text-align: center;
	}
}

.link {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(20pt);
	color: $black;
	margin-top: 20px;
	display: block;
}

.deco__text {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(32pt);
	color: $black;
	@include breakpoint(medium down) {
		font-size: rem-calc(22pt);
	}
}

.boxed__content--alt-header {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(32px);
	color: get-color(secondary);
	text-align: center;
	text-transform: uppercase;
	@include breakpoint(medium down) {
		font-size: rem-calc(28pt);
	}
}

.boxed__content--alt p {
	font-family: $body-font-family;
	font-weight: 700;
	font-size: rem-calc(18pt);
	color: $white;
	text-align: center;
	@include breakpoint(medium down) {
		font-size: rem-calc(16pt);
		font-weight: 400;
	}
}

.boxed__content--header-alt {
	color: get-color(primary);
}

.product__title {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(32pt);
	color: $white;
	text-align: center;
	@supports (-webkit-text-stroke: 1px black) {
	    -webkit-text-stroke: 1px black;
	    -webkit-text-fill-color: white;
	}
}

.characteristic, .characteristic__info {
	font-family: $body-font-family;
	font-weight: 400;
	font-size: rem-calc(18pt);
	color: $black;
}

.characteristics__header {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(20pt);
	color: $black;
}

.info__list--desc {
	font-family: $body-font-family;
	font-weight: 700;
	font-size: rem-calc(18pt);
	color: $white;
}

.info__list--item {
	font-family: $body-font-family;
	font-weight: 400;
	font-size: rem-calc(18pt);
	color: $white;
}

.step__no--text {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(210pt);
	color: get-color(secondary);
	font-variant-numeric: lining-nums;
	@supports (-webkit-text-stroke: 1px black) {
	    -webkit-text-stroke: 1px black;
	    -webkit-text-fill-color: get-color(secondary);
	}
}

.step__info--header {
	font-family: $body-font-family;
	font-weight: 900;
	color: get-color(primary);
	text-align: left;
	font-size: rem-calc(32pt);
}

.qa__head h3 {
	font-family: $body-font-family;
	font-weight: 900;
	font-size: rem-calc(70pt);
	color: get-color(primary);
	text-align: center;
	@include breakpoint(medium down) {
		font-size: rem-calc(32pt);
	}
}

.qa__head p {
	font-family: $body-font-family;
	font-weight: 700;
	font-size: rem-calc(18pt);
	color: $black;
	text-align: center;
}

.accordion-title {
	font-family: $body-font-family;
	font-weight: 700;
	font-size: rem-calc(18pt);
	color: $black;
}

.accordion-content p {
	font-family: $body-font-family;
	font-weight: 400;
	font-size: rem-calc(18pt);
	color: $black;
}

.recipe__baking--method h4 {
	font-family: $body-font-family;
	font-weight: 900;
	color: get-color(secondary);
	text-align: left;
	font-size: rem-calc(32pt);
}

.recipe__ingredient {
	font-family: $body-font-family;
	font-weight: 700;
	font-size: rem-calc(18pt);
	color: $black;
}

.recipe__base--title {
	font-family: $body-font-family;
	font-weight: 900;
	color: get-color(secondary);
	text-align: center;
	font-size: rem-calc(32pt);
	@include breakpoint(medium down) {
		font-size: rem-calc(26pt);
	}
}

.boxed__content .highlighted__recipe--instruction {
	font-weight: 900;
}

// ONLY ON HOMEPAGE

.home {
	.intro__deco--text {
		font-family: $rukolaFont;
		font-weight: 400;
		font-size: rem-calc(50pt);
		color: get-color(secondary);
		text-shadow: 2px 2px $black;
		text-align: left;
		@include breakpoint(medium down){
			font-size: rem-calc(40pt);
		}
	}
	.intro__header {
		font-family: $body-font-family;
		font-weight: 100;
		color: $white;
		text-align: left;
		-webkit-text-stroke: 0px white;
		-webkit-text-fill-color: white;
		@include breakpoint(medium down){
			font-size: rem-calc(28pt);
		}
		font-size: 1.8rem;
		line-height: 2rem;
		margin: 0.5rem 0 0 0;
	}
	.section__header {
		font-family: $body-font-family;
		font-weight: 900;
		font-size: rem-calc(70pt);
		color: get-color(primary);
		text-align: center;
		@include breakpoint(medium down){
			font-size: rem-calc(38pt);
		}
	}
}

.footer__quote--excerpt {
	margin-bottom: 1em;
}

p {
	margin-block-end: .2em
}

.quote__section .boxed__content .form__desc--text {
	text-align: left;
	font-size: 14px;
	margin-bottom: 2rem;
	color: #cacaca;
}